import { useState, useEffect } from 'react';
import useStyles from './styles';
import useBarcodeScanner from 'hooks/use-barcode-scanner';
import BARCODE_TYPE from 'enums/barcode-type.enums';
/* Components */
import { ClassNameMap, Icon } from '@mui/material';
import BarcodeScannerIcon from 'assets/scan-icon/BarcodeScanner.png';

interface CodeScannerProps {
  country?: string | null;
  ean: string;
  setEan: (text: string) => void;
  upc: string;
  setUpc: (text: string) => void;
  onSearch?: (text: string) => void;
  customViewportId?: string;
  size?: number;
  preOpen?: boolean;
  disabled?: boolean;
}

const CodeScanner = ({
  country,
  ean,
  setEan,
  upc,
  setUpc,
  onSearch,
  customViewportId,
  size = 25,
  preOpen = false,
  disabled = false,
}: CodeScannerProps) => {
  const classes: ClassNameMap = useStyles();
  const IS_US_CA_USER = ['us', 'ca'].includes(country ?? '');
  const [hiddenErrorPopup, setHiddenErrorPopup] = useState<boolean>(preOpen);
  const [renderScannerPopup, activateScanner, onDestroy] = useBarcodeScanner(
    (code: string) => {
      if (IS_US_CA_USER) appendCode(code, 'upc');
      else appendCode(code, 'ean');
    },
    {
      customViewportId: customViewportId,
      disabledSnackbar: hiddenErrorPopup,
    },
  );

  const appendCode = (code: string, kind: string): void => {
    if (kind === 'ean') setEan(code);
    else setUpc(code);
    onSearch && onSearch(code);
  };

  useEffect(() => {
    if (preOpen)
      activateScanner(!IS_US_CA_USER ? BARCODE_TYPE.EAN : BARCODE_TYPE.UPC);

    return () => {
      onDestroy();
    };
  }, []);

  return (
    <>
      <Icon
        onClick={() => {
          if (disabled) return;
          hiddenErrorPopup && setHiddenErrorPopup(false);
          activateScanner(!IS_US_CA_USER ? BARCODE_TYPE.EAN : BARCODE_TYPE.UPC);
        }}
        className={classes.scannerIcon}
        style={{
          height: size,
          width: size,
        }}
      >
        <img alt="Icon" src={BarcodeScannerIcon} height={size} width={size} />
      </Icon>

      {renderScannerPopup()}
    </>
  );
};

export default CodeScanner;
