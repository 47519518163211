import { ReactElement, MouseEvent, useEffect, useState } from 'react';
import useStyles from './styles';
import { Trans, useTranslation } from 'react-i18next';
import { BACKEND_URL, INVENTORY_URL } from 'api';
import { isMobile } from 'utils';
import {
  ExportUserSDSUpdatedDto,
  LiteralInterface,
  RowPerPageType,
  SDSInterface,
} from 'interfaces';
import APP_PAGE from 'enums/page.enums';
/* Components */
import {
  Alert,
  Button,
  CircularProgress,
  ClassNameMap,
  Menu,
  MenuItem,
  TablePagination,
  Typography,
} from '@mui/material';
import {
  TravelExplore,
  MoreHoriz,
  Info as InfoIcon,
} from '@mui/icons-material';
import SubstanceDetailPopup from 'components/popup/sds-detail/substance-detail-popup';
import CardTable from 'components/card-table/card-table-desktop';
import SearchBar from 'components/search-bar';
import CardTableMobile from 'components/card-table/card-table-mobile';
import CustomTooltip from 'components/custom-tooltip';
import CardTableLoadingDesktop from 'components/card-table/card-table-desktop/card-table-loading';
import CardTableLoadingMobile from 'components/card-table/card-table-mobile/card-table-loading';
import CodeScanner from 'components/code-scanner';

interface ActionMenuOption {
  id: string;
  title: string;
  onClick: () => void;
  tooltip?: string;
}

interface MySDSProps {
  data: ExportUserSDSUpdatedDto | null;
  lastUpdated: string | null;
  literal: LiteralInterface | null;
  status: string | null;
  onRefresh: () => void;
  onRenew: () => void;
  onSwitchPage: (name: APP_PAGE, defaultSearch?: string) => void;
}

const MySDS = ({
  data,
  lastUpdated,
  literal,
  status,
  onRefresh,
  onRenew,
  onSwitchPage,
}: MySDSProps) => {
  const { t } = useTranslation();
  const classes: ClassNameMap = useStyles();
  const [detailPopup, setDetailPopup] = useState<ReactElement | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [searchText, setSearchText] = useState<string>('');
  const [searchData, setSearchData] = useState<Array<SDSInterface>>([]);
  const [tableData, setTableData] = useState<Array<SDSInterface>>([]);
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<RowPerPageType>(10);
  const [menuActionAnchor, setMenuActionAnchor] =
    useState<null | HTMLButtonElement>(null);
  const openActionMenu: boolean = Boolean(menuActionAnchor);
  const token = localStorage.getItem('access_token');

  const searchSds = (search_text: string): void => {
    const s = search_text ? search_text.toLowerCase() : '';
    const splitted = s.split(/\s|,|;|:|-|\(|\)|'|"|\.|<|>|\||-|_/g);
    const convertedTable = [];
    for (const sdsId in data) {
      const sds = data[Number(sdsId)];
      if (!s) {
        convertedTable.push(sds);
        continue;
      }
      let matched = true;
      for (const index in splitted) {
        const value = splitted[index];
        if (!value) continue;
        if (
          !(
            (sds.product_name &&
              sds.product_name.toLowerCase().includes(value)) ||
            (sds.supplier_name &&
              sds.supplier_name.toLowerCase().includes(value)) ||
            (sds.product_code &&
              sds.product_code.toLowerCase().includes(value)) ||
            (sds.ean_code && sds.ean_code.toLowerCase() === value) ||
            (sds.upc_code && sds.upc_code.toLowerCase() === value)
          )
        ) {
          matched = false;
          break;
        }
      }
      if (matched) convertedTable.push(sds);
    }

    setSearchData(convertedTable);
    if (page !== 0) {
      setPage(0);
      setLoading(false);
      return;
    }

    if (convertedTable.length <= rowsPerPage) {
      setTableData(convertedTable);
      setLoading(false);
      return;
    }
    setTableData(
      convertedTable.slice(page * rowsPerPage, (page + 1) * rowsPerPage),
    );
    setLoading(false);
  };

  const renderActonBtnGroup = (): ReactElement => {
    const menuActionOptions: Array<ActionMenuOption> = [
      {
        id: 'get-latest-change',
        title: t('common:get_latest_change'),
        tooltip: t('common:get_latest_change_tooltip'),
        onClick: onRefresh,
      },
      {
        id: 'renew-data',
        title: t('common:renew_data'),
        tooltip: t('common:renew_data_tooltip'),
        onClick: () => {
          if (window.confirm(t('common:renew_confirm_text')) === true)
            onRenew();
        },
      },
    ];

    return (
      <div className={classes.headerActionGroup}>
        <div className={classes.headerActionBtnGroup}>
          {token && isMobile() && (
            <Typography
              component="a"
              target="_blank"
              href={`${INVENTORY_URL}/my-sds/?qr_login_token=${token}`}
            >
              {t('common:open_web_version')}
            </Typography>
          )}

          <CustomTooltip title={t('common:global_sds_search')} placement="top">
            <TravelExplore
              onClick={() => onSwitchPage(APP_PAGE.GLOBAL_SEARCH_SDS)}
              className={classes.actionIcon}
            />
          </CustomTooltip>

          <Button
            onClick={e => setMenuActionAnchor(e.currentTarget)}
            sx={{
              fontSize: '12px',
              padding: '2px 8px',
              backgroundColor: '#fff',
              fontWeight: 600,
            }}
            variant={'outlined'}
            color={'primary'}
          >
            <MoreHoriz width={12} height={12} />
          </Button>
        </div>

        {status ? (
          <div className={classes.progressStatus}>
            <CircularProgress style={{ color: '#898CFA' }} size={18} />
            <Typography style={{ color: '#898CFA' }} variant="button">
              {status}
            </Typography>
          </div>
        ) : lastUpdated ? (
          <Typography variant={isMobile() ? 'caption' : 'body1'}>
            {lastUpdated}
          </Typography>
        ) : null}

        <Menu
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          anchorEl={menuActionAnchor}
          open={openActionMenu}
          onClose={e => setMenuActionAnchor(null)}
          className={classes.actionGroupMenu}
        >
          {menuActionOptions.map(option => (
            <MenuItem
              key={option.id}
              onClick={(e: MouseEvent<HTMLLIElement>) => {
                e.preventDefault();
                e.stopPropagation();
                option.onClick();
                setMenuActionAnchor(null);
              }}
            >
              {option.tooltip ? (
                <CustomTooltip title={option.tooltip} placement="left">
                  <span>{option.title}</span>
                </CustomTooltip>
              ) : (
                <span>{option.title}</span>
              )}
            </MenuItem>
          ))}
        </Menu>
      </div>
    );
  };

  const renderSubstanceList = (): ReactElement => {
    if (searchData.length < 1) {
      return (
        <div className={classes.noSearchDataAlert}>
          <Alert
            severity="info"
            color="warning"
            sx={{
              width: isMobile() ? 'auto' : '700px',
              borderRadius: '5px',
              border: '2px solid #b0840c',
              padding: '10px',
            }}
            icon={<InfoIcon fontSize="inherit" sx={{ color: '#b0840c' }} />}
          >
            <Typography
              sx={{ fontSize: '16px', color: 'black', fontWeight: '600' }}
            >
              <Trans
                ns={'common'}
                i18nKey={'did_not_match_sds_in_library'}
                values={{ keyword: searchText }}
                components={[<span style={{ backgroundColor: 'yellow' }} />]}
              />
            </Typography>
          </Alert>
          <Typography
            component="a"
            href="#"
            onClick={() => onSwitchPage(APP_PAGE.GLOBAL_SEARCH_SDS, searchText)}
          >
            {' '}
            {t('common:redo_search_in_global_database')}{' '}
          </Typography>
        </div>
      );
    }

    return (
      <>
        {isMobile() ? (
          <div className={classes.cardTableContainer}>
            <CardTableMobile
              data={tableData}
              onViewSubstanceDetail={(data, toSection) =>
                setDetailPopup(
                  <SubstanceDetailPopup
                    sds={data}
                    literal={literal}
                    onClose={() => setDetailPopup(null)}
                    toSection={toSection}
                  />,
                )
              }
            />
          </div>
        ) : (
          <div className={classes.cardTableContainer}>
            <CardTable
              data={tableData}
              onViewSubstanceDetail={(data, toSection) =>
                setDetailPopup(
                  <SubstanceDetailPopup
                    sds={data}
                    literal={literal}
                    onClose={() => setDetailPopup(null)}
                    toSection={toSection}
                  />,
                )
              }
            />
          </div>
        )}

        {searchData.length > 0 && (
          <div className={classes.cardTableActionContainer}>
            <TablePagination
              rowsPerPageOptions={[10, 20, 50]}
              component="div"
              count={searchData.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={(_, value) => setPage(value)}
              onRowsPerPageChange={e => {
                setRowsPerPage(+e.target.value as RowPerPageType);
                setPage(0);
              }}
              style={{
                backgroundColor: '#F2F5F7',
                display: 'flex',
              }}
              labelRowsPerPage={`${t('common:rows_per_page')}`}
            />
          </div>
        )}
      </>
    );
  };

  const renderLoading = (): ReactElement => {
    if (isMobile()) return <CardTableLoadingMobile />;
    return <CardTableLoadingDesktop rowCount={2} />;
  };

  useEffect(() => {
    if (!data) return;
    searchSds('');
  }, [data]);

  useEffect(() => {
    if (!searchData || !searchData.length) return;
    if (searchData.length <= rowsPerPage) {
      setTableData(searchData);
      return;
    }
    setTableData(
      searchData.slice(page * rowsPerPage, (page + 1) * rowsPerPage),
    );
  }, [page, rowsPerPage]);

  return (
    <>
      <div className={classes.container}>
        <div className={classes.header}>
          <div className={classes.logoContainer}>
            <img
              alt="logo"
              src={
                literal?.logo
                  ? `${BACKEND_URL}${literal.logo}`
                  : '/images/logo.svg'
              }
              className={classes.logo}
            />
          </div>
          {token && !isMobile() && (
            <Typography
              component="a"
              target="_blank"
              href={`${INVENTORY_URL}/my-sds/?qr_login_token=${token}`}
            >
              {t('common:open_web_version')}
            </Typography>
          )}
          {renderActonBtnGroup()}
        </div>

        <div className={classes.subHeader}>
          <div className={classes.pageTitle}>
            {t('common:sds_library_for')}
            <span style={{ fontWeight: 600 }}>{literal?.name}</span>
          </div>
          {isMobile() && (
            <CodeScanner
              country={literal?.country}
              ean={searchText}
              setEan={(text: string) => {
                setLoading(true);
                setSearchText(text);
                searchSds(text);
              }}
              upc={searchText}
              setUpc={(text: string) => {
                setLoading(true);
                setSearchText(text);
                searchSds(text);
              }}
              onSearch={(text: string) => {
                setLoading(true);
                setSearchText(text);
                searchSds(text);
              }}
              customViewportId={'interactive-search'}
              size={40}
            />
          )}
        </div>

        <div className={classes.searchBarWrapper}>
          <SearchBar
            id="search-input"
            placeHolder={t('common:search_placeholder')}
            onSearch={text => {
              setLoading(true);
              setSearchText(text);
              searchSds(text);
            }}
            onClear={() => {
              setLoading(true);
              setSearchText('');
              searchSds('');
            }}
            onChange={text => setSearchText(text)}
            defaultValue={searchText}
            searchValue={searchText}
          />
        </div>

        {loading ? renderLoading() : renderSubstanceList()}
      </div>

      {detailPopup}
    </>
  );
};

export default MySDS;
